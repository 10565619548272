import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["toc", "socialSharing"];

  connect() {
    console.log("Blog toolbar controller connected");
    useClickOutside(this, { element: this.tocTarget });
    useClickOutside(this, { element: this.socialSharingTarget });
  }

  toggleToc(event) {
    event.stopPropagation();

    if (this.hasTocTarget) {
      this.toggleElement(this.tocTarget);
    } else {
      console.error("TOC target not found");
    }
  }

  toggleSocialSharing(event) {
    event.stopPropagation();

    if (this.hasSocialSharingTarget) {
      this.toggleElement(this.socialSharingTarget);
    } else {
      console.error("Share target not found");
    }
  }

  toggleElement(element) {
    const isHidden = element.classList.contains("hidden");

    if (isHidden) {
      element.classList.remove("hidden");
      setTimeout(() => {
        element.classList.remove("translate-y-full", "opacity-0");
      }, 10);
    } else {
      this.closeElement(element);
    }

    // Close the other element if it's open
    const otherElement =
      element === this.tocTarget ? this.socialSharingTarget : this.tocTarget;
    if (otherElement && !otherElement.classList.contains("hidden")) {
      this.closeElement(otherElement);
    }
  }

  closeElement(element) {
    element.classList.add("translate-y-full", "opacity-0");
    this.hideElementAfterTransition(element);
  }

  hideElementAfterTransition(element) {
    const hideElement = () => {
      element.classList.add("hidden");
      element.removeEventListener("transitionend", hideElement);
    };
    element.addEventListener("transitionend", hideElement);
  }

  // This method will be called when a click outside the controller's element is detected
  clickOutside(event) {
    console.log("Clicked outside");
    if (this.hasTocTarget && !this.tocTarget.classList.contains("hidden")) {
      this.closeElement(this.tocTarget);
    }
    if (
      this.hasSocialSharingTarget &&
      !this.socialSharingTarget.classList.contains("hidden")
    ) {
      this.closeElement(this.socialSharingTarget);
    }
  }
}
