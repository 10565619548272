import { Controller } from "stimulus";

export default class extends Controller{
  static targets = ["toggleButton", "formContainer", "details"];

  toggleForm(){
    if(this.formContainerTarget.classList.contains("hidden")){
      let toggleButtonContent = this.toggleButtonTarget.querySelector("span");
      let toggleButtonIcon = this.toggleButtonTarget.querySelector("svg");

      toggleButtonContent.innerHTML = "Guardar"
      toggleButtonContent.classList.add("text-orange-500");
      toggleButtonIcon.classList.add("text-orange-500");

    } else {
      let form = this.formContainerTarget.querySelector("form");
      form.submit();
    }

    this.formContainerTarget.classList.toggle("hidden");
    this.detailsTarget.classList.toggle("hidden");
  }
}