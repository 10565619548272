import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["letter"];

  connect() {
    // Ensure the first letter is active on connect
    this.setActiveLetter({ target: this.letterTargets[0] });
  }

  setActiveLetter(event) {
    this.letterTargets.forEach((letter) => {
      letter.classList.remove("alphabet-nav__current-letter", "active");
    });
    event.target.classList.add("alphabet-nav__current-letter", "active");
  }
}
