import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import Spanish from "@uppy/locales/lib/es_MX";
import { Notyf } from "notyf";

export default class extends Controller {
  static values = { url: String };
  static targets = [
    "userIdField",
    "attachableTypeField",
    "attachableIdField",
    "fileField",
  ];

  connect() {
    this.uppy = Uppy({
      debug: true,
      autoProceed: false,
      locale: Spanish,
      restrictions: {
        maxFileSize: 120 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["audio/*"],
      },
    });

    this.uppy.use(Dashboard, {
      target: this.element,
      inline: true,
      showProgressDetails: true,
      proudlyDisplayPoweredByUppy: false,
      note: "Solo se permiten archivos de audio de hasta 120 MB",
      height: 320,
    });

    this.uppy.use(XHRUpload, {
      endpoint: this.urlValue, // Set to your Rails server endpoint
      formData: true,
    });

    this.uppy.on("upload-success", (response) => {
      const notyf = new Notyf({
        types: [
          {
            type: "info",
            background: "black",
            icon: false,
          },
        ],
      });

      notyf.open({
        type: "info",
        message:
          "No cierres esta ventana! Por favor, espera hasta ser redirigido a la página de inicio para confirmar la subida de las pistas.",
      });

      const formData = new FormData();
      formData.append(
        "audio_attachment[user_id]",
        this.userIdFieldTarget.value
      );
      formData.append(
        "audio_attachment[attachable_type]",
        this.attachableTypeFieldTarget.value
      );
      formData.append(
        "audio_attachment[attachable_id]",
        this.attachableIdFieldTarget.value
      );
      formData.append("audio_attachment[file]", response.data);

      Rails.ajax({
        url: "/adjuntos-de-audio",
        type: "post",
        data: formData,
        success: function (res) {
          let notyf = new Notyf({
            position: { x: "right", y: "top", duration: 2000 },
          });
          notyf.success("Las pistas fueron subidas con éxito");
        },
        error: function (err) {
          let notyf = new Notyf({
            position: { x: "right", y: "top", duration: 2000 },
          });
          notyf.error(
            "Hubo un problema al subir las pistas. Por favor intenta nuevamente o comunicate con soporte"
          );
        },
      });
    });
  }

  disconnect() {
    this.uppy.close();
  }
}
