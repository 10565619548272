import { Controller } from "stimulus";

export default class extends Controller {
  dismiss() {
    const url = this.data.get("url");

    if (url !== null) {
      Rails.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        success: (response, data) => {
          this._removeElementFromDOM();
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this._removeElementFromDOM();
    }
  }

  _removeElementFromDOM() {
    this.element.classList.add("dismiss-fade-out");

    this.element.addEventListener(
      "transitionend",
      () => {
        this.element.remove();
      },
      { once: true }
    );
  }
}
