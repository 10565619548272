import { Controller } from "stimulus";

import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";
import axios from "axios";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.css";

export default class extends Controller {
  static targets = ["field", "fileZone", "form"];
  static values = { url: String };

  initialize() {
    let self = this;
    this.uppy = new Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 524288000,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        allowedFileTypes: ["application/zip", "application/pdf"],
      },
    })
      .use(Dashboard, {
        trigger: ".UppyModalOpenerBtn",
        inline: true,
        target: this.fileZoneTarget,
        proudlyDisplayPoweredByUppy: false,
        replaceTargetContent: true,
        showProgressDetails: true,
        note: "Se admite la subida de archivos de audio comprimidos o PDF",
        height: 470,
        metaFields: [
          { id: "name", name: "Name", placeholder: "file name" },
          {
            id: "caption",
            name: "Caption",
            placeholder: "describe what the image is about",
          },
        ],
        browserBackButtonClose: false,
      })
      .use(AwsS3, {
        getUploadParameters(file) {
          const { name, type, size } = file.data;
          return axios("/s3/params", {
            method: "get",
            // Send and receive JSON.
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            params: {
              filename: name,
              type: type,
            },
          })
            .then((response) => {
              // Parse the JSON response.
              return response;
            })
            .then((data) => {
              // Return an object in the correct shape.
              const uploadedFile = {
                method: data.data.method,
                url: data.data.url,
                fields: data.data.fields,
                storage: "cache",
                filename: name,
                size: size,
                mime_type: type,
              };

              self.buildAttachmentObject(data, name, size, type);
              return uploadedFile;
            });
        },
      });

    this.uppy.on("complete", (result) => {
      let form = this.formTarget;
      let formData = new FormData(form);

      formData.append(
        this.fieldTarget.name,
        JSON.stringify(this.attachmentAttribute)
      );
      formData.append(
        "attachment[attachment_type]",
        this.attachmentAttribute.metadata.mime_type
      );

      Rails.ajax({
        url: this.urlValue,
        type: this.formTarget.method,
        data: formData,
        success: function (response) {},
        error: function (response) {},
      });
    });
  }

  handleUploadedFile(file, name, size, type) {
    this.attachmentAttribute = {
      id: null,
      attachment: this.buildAttachmentObject(file, name, size, type),
    };
  }

  buildAttachmentObject(fileData, name, size, type) {
    this.attachmentAttribute = {
      id: fileData.data.fields.key.match(/cache\/(.+)/)[1],
      storage: "cache",
      metadata: {
        size: size,
        filename: name,
        mime_type: type,
      },
    };

    return this.attachmentAttribute;
  }
}
