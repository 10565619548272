document.addEventListener("turbolinks:load", () => {
  const links = document.querySelectorAll("[data-behavior=soft-scroll-link]");
  for (const link of links) {
    link.addEventListener("click", clickHandler);
  }

  function clickHandler(e) {
    e.preventDefault();
    const navbar = document.querySelector("[data-behavior=main-navbar]");
    let offsetTop;

    if (navbar) {
      const navbarHeight = navbar.getBoundingClientRect().height;
      const heightTolerance = 0;
      const targetId = this.getAttribute("href");
      const targetElement = document.querySelector(targetId);

      if (targetElement) {
        offsetTop = targetElement.offsetTop - (navbarHeight + heightTolerance);
      }
    }

    // Fallback for offsetTop if navbar is not found
    if (offsetTop === undefined) {
      offsetTop = document.querySelector(this.getAttribute("href")).offsetTop;
    }

    scroll({
      top: offsetTop,
      behavior: "smooth",
    });
  }
});
