import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalContainer", "modal", "backgroundOverlay"];

  connect() {
    this.closeModal();
  }

  handleModalClick(e) {
    e.preventDefault();
    this.toggleModal();
  }

  handleKeyup(e) {
    if (e.key === "Escape") {
      this.closeModal();
    }
  }

  toggleModal() {
    if (this._isModalOpen()) {
      this.closeModal();
    } else {
      this.showModal();
    }
  }

  closeModal() {
    this.modalTarget.classList.add("translate-y-4", "opacity-0");
    this.modalTarget.classList.remove("translate-y-0", "opacity-100");
    this._hideBackgroundOverlay();
    this._enableScroll();

    setTimeout(() => {
      this.modalContainerTarget.classList.add("hidden");
      this.modalTarget.classList.remove("translate-y-4");
      this.modalTarget.classList.add("translate-y-full");
    }, 300);
  }

  showModal() {
    this.modalContainerTarget.classList.remove("hidden");
    this._disableScroll();

    requestAnimationFrame(() => {
      this.modalTarget.classList.add("translate-y-0", "opacity-100");
      this.modalTarget.classList.remove("translate-y-full", "opacity-0");
      this._showBackgroundOverlay();
    });
  }

  _showBackgroundOverlay() {
    this.backgroundOverlayTarget.classList.remove("opacity-0");
    this.backgroundOverlayTarget.classList.add("opacity-75");
  }

  _hideBackgroundOverlay() {
    this.backgroundOverlayTarget.classList.remove("opacity-75");
    this.backgroundOverlayTarget.classList.add("opacity-0");
  }

  _isModalOpen() {
    return !this.modalContainerTarget.classList.contains("hidden");
  }

  _disableScroll() {
    // Get current scroll width
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflow = "hidden";
  }

  _enableScroll() {
    document.body.style.paddingRight = "";
    document.body.style.overflow = "";
  }
}
