import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  toggleMenu(event) {
    event.preventDefault();
    if (this.menuTarget.classList.contains("opacity-0")) {
      console.log("showing menu");
      this.showMenu();
    } else {
      console.log("closing menu");
      this.closeMenu();
    }
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.closeMenu();
    }
  }

  showMenu() {
    this.menuTarget.classList.remove("hidden", "opacity-0", "scale-95");
    this.menuTarget.classList.add("opacity-100", "scale-100");
    document.addEventListener("click", this.handleOutsideClick);
  }

  closeMenu() {
    this.menuTarget.classList.remove("opacity-100", "scale-100");
    this.menuTarget.classList.add("hidden", "opacity-0", "scale-95");
    document.removeEventListener("click", this.handleOutsideClick);
  }
}
