import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.close = this.close.bind(this); // Bind to maintain a consistent `this`.
    this.updatePosition = this.updatePosition.bind(this); // Bind to maintain a consistent `this`.
  }

  toggle(event) {
    event.stopPropagation();

    if (this.menuTarget.classList.contains("hidden")) {
      this._open(event.currentTarget.getBoundingClientRect());
      document.addEventListener("click", this.close);
      window.addEventListener("scroll", this.updatePosition);
    } else {
      this._hide();
    }
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this._hide();
    }
  }

  _open(triggerRect) {
    this.updatePosition(triggerRect); // Open and position menu
  }

  updatePosition(triggerRect = null) {
    if (!triggerRect) {
      triggerRect =
        this.menuTarget.previousElementSibling.getBoundingClientRect();
    }
    const menu = this.menuTarget;

    // Set initial style to get dimensions if menu is hidden
    if (menu.classList.contains("hidden")) {
      menu.style.visibility = "hidden";
      menu.style.position = "fixed";
      menu.style.left = "0";
      menu.style.top = "0";
      menu.classList.remove("hidden");
    }

    const menuRect = menu.getBoundingClientRect();

    let left = triggerRect.left - menuRect.width + triggerRect.width;
    if (left < 0) {
      left = 10; // Adjust for left edge of the window
    }

    menu.style.left = `${left}px`;
    menu.style.top = `${triggerRect.bottom}px`;
    menu.style.visibility = "visible"; // Make menu visible
  }

  _hide() {
    const menu = this.menuTarget;
    menu.classList.add("hidden");
    // Clean up the styles
    menu.style.visibility = "";
    menu.style.position = "";
    menu.style.left = "";
    menu.style.top = "";

    document.removeEventListener("click", this.close);
    window.removeEventListener("scroll", this.updatePosition); // Remove scroll listener
  }

  close(event) {
    if (event && !this.element.contains(event.target)) {
      this._hide();
    }
  }
}
