import {Controller} from "stimulus"

import {enter, leave} from 'el-transition'; 

export default class extends Controller{
  static targets = ["panel", "panelContainer", "button", "overlay"];

  togglePanel(e){
    e.stopPropagation();

    if(this.panelContainerTarget.classList.contains("hidden")){
      this.panelContainerTarget.classList.remove("hidden")
      enter(this.panelTarget);
      enter(this.overlayTarget);
    } else {
      this.closePanel(e);
    }
  }

  closePanel(event){
    leave(this.panelTarget);
    leave(this.overlayTarget);
    this.panelContainerTarget.classList.add("hidden");
  }

  handleKeyUp(event){
    event.preventDefault();
    if(event.keyCode == 27){
      this.closePanel();
    }
  }

  handleWindowClick(event){
    if(event.target == this.overlayTarget){
      this.closePanel()
    }
  }
}