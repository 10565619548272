import {Controller} from "stimulus";

export default class extends Controller{
  static targets = ["button", "reel", "field"];

  connect(){
    if(this._isCheckboxOn()){
      this._turnCheckboxOn()
    }
  }

  handleCheckboxClick(e){
    if(this._isCheckboxOn()){
      this._turnCheckboxOff()
    } else{
      this._turnCheckboxOn()
    }
  }

  _isCheckboxOn(){
    return eval(this.reelTarget.dataset.checkboxStatus)
  }

  _turnCheckboxOn(){
    this.reelTarget.dataset.checkboxStatus = true;
    this.fieldTarget.value = true;

    this.buttonTarget.classList.remove("translate-x-0")
    this.reelTarget.classList.remove("bg-gray-300");

    this.buttonTarget.classList.add("translate-x-5");
    this.reelTarget.classList.add("bg-indigo-600");
  }

  _turnCheckboxOff(){
    this.reelTarget.dataset.checkboxStatus = false;
    this.fieldTarget.value = false;
    
    if(this.reelTarget.classList.contains("bg-indigo-600")){
      this.buttonTarget.classList.remove("translate-x-5");
      this.reelTarget.classList.remove("bg-indigo-600");
    }
    this.reelTarget.classList.add("bg-gray-300");
    this.buttonTarget.classList.add("translate-x-0")
  }
}