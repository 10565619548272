import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput", "image", "form"];
  static values = { url: String };

  openFileUploadModal() {
    this.fileInputTarget.click();
  }

  handleFileUpload(e) {
    let file = e.target.files[0];
    this._uploadFile(file);
    this._replaceAvatar(file);
  }

  _uploadFile(file) {
    console.log(file);
    let form = new FormData(this.formTarget);
    form.append("profile[avatar]", file);
    Rails.ajax({
      type: "post",
      url: this.urlValue,
      data: form,
    });
  }

  _replaceAvatar(file) {
    let reader = new FileReader();
    let image = this.imageTarget;

    reader.addEventListener(
      "load",
      function () {
        image.src = reader.result;
        document.querySelector("[data-behavior=navbar-avatar]").src =
          reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }
}
