import {Controller} from "stimulus";

export default class extends Controller{
  static targets = ["circle"];

  connect(){
    this.radius = this.circleTarget.r.baseVal.value;
    this.circumference = this.radius * 2 * Math.PI;
  
    this.circleTarget.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    this.circleTarget.style.strokeDashoffset = `${this.circumference}`;
  }

  setProgress(e){
    let progressPercentage = e.target.currentTime / e.target.duration * 100;

    const offset = this.circumference - progressPercentage / 100 * this.circumference;
    this.circleTarget.style.strokeDashoffset = offset;
  }
}