import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "form",
    "emptyState",
    "resourceList",
    "submitButton",
    "submitLoading",
    "submitText",
  ];

  static values = {
    url: String,
  };

  submit(evt) {
    evt.preventDefault();
    this._toggleSubmitText();
    this._toggleSubmitLoading();

    Rails.ajax({
      url: this.formTarget.action,
      type: "post",
      data: new FormData(this.formTarget),
      success: (data) => {
        this._toggleSubmitText();
        this._toggleSubmitLoading();
        this._hideEmptyStateIfApplicable();
        this._resetForm();
        this._hideForm();
        this.resourceListTarget.insertAdjacentHTML("afterBegin", data.template);
      },
      error: (data) => {
        this._toggleSubmitText();
        this._toggleSubmitLoading();
      },
    });
  }

  onInput(evt) {
    // If the input length is greater than 5 characters, enable the submit button
    if (evt.target.value.length > 5) {
      this._enableSubmit();
    } else {
      this.submitButtonTarget.classList.add("cursor-not-allowed");
      this.submitButtonTarget.classList.remove("cursor-pointer");
      this.submitButtonTarget.disabled = true;
    }
  }

  hide() {
    this.containerTarget.classList.add("hidden");
  }

  show() {
    this.containerTarget.classList.remove("hidden");
  }

  _resetForm() {
    this.formTarget.reset();
  }

  _hideForm() {
    this.containerTarget.classList.add("hidden");
  }

  _toggleSubmitText() {
    this.submitTextTarget.classList.toggle("hidden");
  }

  _toggleSubmitLoading() {
    this.submitLoadingTarget.classList.toggle("hidden");
  }

  _enableSubmit() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove("cursor-not-allowed");
    this.submitButtonTarget.classList.add("cursor-pointer");
    this.submitButtonTarget.disabled = false;
  }

  _hideEmptyStateIfApplicable() {
    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.classList.add("hidden");
    }
  }
}
