import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["feed", "filter", "tagsContainer", "searchInput"];
  static values = { url: String };

  filter(e) {
    const url = `${window.location.pathname}?${this._formattedParams(
      e.target.dataset.name,
      e.target.dataset.value
    )}`;
    this._fetchCourses(url);
  }

  sort(e) {
    const url = `${window.location.pathname}?${this._formattedParams(
      e.target.name,
      e.target.value
    )}`;
    this._fetchCourses(url);
  }

  search(e) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const value = e.target.value;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("busqueda", value);
      const url = `${window.location.pathname}?${urlParams.toString()}`;
      this._fetchCourses(url);
    }, 200);
  }

  removeFromQueryString(e) {
    const tag = e.target.closest("[data-behavior=filter-tag]");
    const value = tag.dataset.value;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(value);
    const url = `${window.location.pathname}?${urlParams.toString()}`;
    tag.remove();
    this.searchInputTarget.value = "";
    this._fetchCourses(url);
  }

  _formattedParams(name, value) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(name, value);
    return urlParams.toString();
  }

  _fetchCourses(url) {
    Rails.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      success: (response) => this._handleSuccessfulResponse(response, url),
      error: (error) => {
        console.log(error);
      },
    });
  }

  _handleSuccessfulResponse(response, url) {
    this._populateFeed(response.entries);
    this._populateTags(response.tags);
    this._pushURL(url);
  }

  _populateFeed(response) {
    this.feedTarget.innerHTML = response;
  }

  _populateTags(tags) {
    this.tagsContainerTarget.innerHTML = tags;
  }

  _pushURL(url) {
    window.history.pushState({}, null, url);
  }
}
