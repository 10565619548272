import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["collapsible", "chevron"];

  connect() {
    if (this.data.get("collapsed")) {
      this.collapsibleTarget.style.maxHeight = "0px";
    } else {
      this.collapsibleTarget.style.maxHeight =
        this.collapsibleTarget.scrollHeight + "px";
    }
  }

  toggleCollapse(e) {
    e.preventDefault();
    const collapsible = this.collapsibleTarget;

    if (collapsible.classList.contains("collapsed")) {
      // Expand with animation
      collapsible.classList.remove("collapsed");
      collapsible.style.maxHeight = collapsible.scrollHeight + "px";

      this.chevronTarget.classList.remove("fa-chevron-down");
      this.chevronTarget.classList.add("fa-chevron-up");

      // After transition, remove maxHeight so it can adjust to content size
      collapsible.addEventListener("transitionend", function handler() {
        collapsible.style.maxHeight = "none";
        collapsible.removeEventListener("transitionend", handler);
      });
    } else {
      // To animate the collapse, first set maxHeight to current scrollHeight
      collapsible.style.maxHeight = collapsible.scrollHeight + "px";

      // Allow repaint before collapsing
      setTimeout(() => {
        collapsible.style.maxHeight = "0px"; // Then collapse it
      }, 0);

      collapsible.addEventListener("transitionend", function handler() {
        collapsible.classList.add("collapsed");
        collapsible.removeEventListener("transitionend", handler);
      });

      this.chevronTarget.classList.remove("fa-chevron-up");
      this.chevronTarget.classList.add("fa-chevron-down");
    }
  }
}
