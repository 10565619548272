import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "hostingPodcastId",
    "loader",
    "nameField",
    "descriptionField",
    "slugField",
    "metaTitleField",
    "metaDescriptionField",
    "podcastEmbedCode",
    "podcastEpisodeNumber",
    "podcastAudioUrl",
    "runTime",
  ];
  static values = { url: String };

  fetchPodcastEpisode(e) {
    this.loaderTarget.classList.remove("hidden");

    this.episodeId = e.target.value;

    let self = this;

    Rails.ajax({
      url: this.urlValue,
      type: "get",
      data: `episode_id=${this.episodeId}`,
      success: function (response) {
        self._handleSuccessfulFetch(response);
      },
      error: function (response) {
        self._handleErrors(response);
      },
    });
  }

  _handleSuccessfulFetch(data) {
    console.log(data);
    this.loaderTarget.classList.add("hidden");

    this.podcastAudioUrlTarget.value = data["episode"]["audio_file_url"];
    this.podcastEpisodeNumberTarget.value = data["episode"]["number"];
    this.podcastEmbedCodeTarget.value = data["oembed"]["html"];

    data["episode"]["title"]
      ? (this.nameFieldTarget.value = data["episode"]["title"])
      : null;
    data["episode"]["description"]
      ? (this.descriptionFieldTarget.value = data["episode"]["description"])
      : null;
    data["episode"]["title"]
      ? (this.metaTitleFieldTarget.value = `${data["episode"]["title"]} - Momentos Sonipedia`)
      : null;
    data["episode"]["description"]
      ? (this.metaDescriptionFieldTarget.value = data["episode"]["description"])
      : null;
    data["episode"]["slug"]
      ? (this.slugFieldTarget.value = data["episode"]["slug"])
      : null;
    data["episode"]["duration"]
      ? (this.runTimeTarget.value = data["episode"]["duration"])
      : null;
  }

  _handleErrors(data) {}
}
