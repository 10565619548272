import { Controller } from "stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = ["messages", "messageField", "form", "submitButton"];
  static values = { userId: Number };

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "LiveStreamMessageChannel", id: this.data.get("id") },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );

    this.formTarget.addEventListener("keydown", (e) => {
      if (e.keyCode == 13) {
        e.preventDefault();
        if (this.messageFieldTarget.value) {
          this.formTarget.submit();
          this.formTarget.reset();
          this.scrollToBottom();
        }
      }
    });
  }

  handleChange(ev) {
    ev.preventDefault();
    let message = ev.target.value;
    if (message.length > 0) {
      this._enableSubmitButton();
    }
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.message && data.message.status == 204) {
      let message = document.querySelector(
        `#live_stream_message_${data.message.live_stream_message_id}`
      );
      message.remove();
    }

    if (data.message && data.message.status != 204) {
      this.messagesTarget.insertAdjacentHTML("beforeend", data.message);
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    this.messagesTarget.scrollBy({
      top: this.messagesTarget.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  _enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove(
      "cursor-not-allowed",
      "bg-indigo-300"
    );
    this.submitButtonTarget.classList.add("bg-indigo-700");
  }

  _disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.remove("bg-indigo-700");
    this.submitButtonTarget.classList.add(
      "cursor-not-allowed",
      "bg-indigo-300"
    );
  }
}
