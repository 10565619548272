import {Controller} from "stimulus";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default class extends Controller{
  static targets = ["template"]

  initialize(){
    tippy(this.element, {
      content: this.templateTarget.innerHTML,
      allowHTML: true,
      theme: this.data.get("theme") || "",
      placement: this.data.get("placement") || "top",
    }) 
  }
}