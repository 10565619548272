import {Controller} from "stimulus";

export default class extends Controller{
  static targets = ["form", "toggleButton"];

  toggle(e){
    e.preventDefault();
    this.formTarget.classList.toggle("hidden");
    if(this.toggleButtonTarget.innerHTML == "Responder"){
      this.toggleButtonTarget.innerHTML = "Ocultar";
    } else{
      this.toggleButtonTarget.innerHTML = "Responder"
    }
  }
}