import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab", "tabPanel" ]

  initialize() {
    this.showTab()
  }

  change(e){
    e.stopPropagation()
    this.index = this.tabTargets.indexOf(e.target)

    this.showTab(this.index)
  }

  showTab() {
    this.tabPanelTargets.forEach((el, i) => {
      if(i==this.index){
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })

    this.tabTargets.forEach((el, i) => {
      let activeClasses = this.data.get("active-classes").trim().split(",");

      if(i==this.index){
        el.classList.add(...activeClasses)
      } else {
        el.classList.remove(...activeClasses)
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index'))
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }

}