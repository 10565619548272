import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollable"];

  scroll() {
    this.scrollableTarget.scrollBy({
      top: this.scrollableTarget.scrollHeight,
      left: 0,
      behavior: "instant",
    });
  }
}
