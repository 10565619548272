import { Controller } from "stimulus";
import swal from "sweetalert";
import videojs from "video.js";
import ahoy from "ahoy.js";

import "video.js/dist/video-js.css";
import "@videojs/themes/fantasy/index.css";

export default class extends Controller {
  static targets = ["player", "playerProgress"];

  initialize() {
    Turbolinks.clearCache();

    this.videoPlayer = videojs(this.playerTarget, {
      controls: true,
      playbackRates: [0.5, 1, 2],
      autoplay: this.data.get("autoplay"),
      fluid: true,
    });

    if (window.userlist) {
      this.userlist = userlist;
    }

    if (window.mixpanel) {
      this.mixpanel = window.mixpanel;
    }

    this.ahoy = ahoy;

    this.videoStarted = false;
    this.videoPlayer.addClass("video-js");
    this.videoPlayer.addClass("vjs-big-play-centered");
    // When the video starts send the 'lesson_started' event to userlist
    this.videoPlayer.on("play", () => {
      this._sendLessonStartedToUserlist();
      this._sendLessonStartedToMixpanel();
      this._sendLessonStartedToAhoy();
      this.videoStarted = true;
    });

    this.videoPlayer.on("ended", (e) => {
      this.goToNextVideo(e);
    });
  }

  goToNextVideo(e) {
    e.preventDefault();
    swal("Serás redirigido al próximo episodio en dos segundos", {
      timer: 2000,
      buttons: {
        cancel: "Cancelar",
        catch: {
          text: "Continuar",
          value: "continue",
        },
      },
    }).then((value) => {
      this._markLessonAsCompleted();

      switch (value) {
        case "continue":
          this._markLessonAsCompleted();
      }
    });
  }

  disconnect() {
    Turbolinks.clearCache();
    this.videoPlayer.dispose();
  }

  _redirectToNextLesson() {
    window.history.pushState(null, window.location.href);
    window.location.replace(
      document.querySelector("[data-behavior=next-lesson-link]").href
    );
  }

  _markLessonAsCompleted() {
    let that = this;
    Rails.ajax({
      url: this.data.get("lesson-completion-url"),
      type: "POST",
      success: function () {
        that._redirectToNextLesson();
      },
    });
  }

  _sendLessonStartedToUserlist() {
    if (!this.videoStarted && this.userlist) {
      this.userlist("track", "lesson_started", {
        lesson_id: this.data.get("lesson-id"),
        lesson_name: this.data.get("lesson-name"),
        course_name: this.data.get("course-name"),
        course_id: this.data.get("course-id"),
        course_cover_url: this.data.get("course-cover-url"),
      });
    }
  }

  _sendLessonStartedToMixpanel() {
    if (!this.videoStarted) {
      this.mixpanel.track("lesson_started", {
        user_id: this.data.get("user-id"),
        username: this.data.get("username"),
        lesson_id: this.data.get("lesson-id"),
        lesson_name: this.data.get("lesson-name"),
        course_name: this.data.get("course-name"),
        course_id: this.data.get("course-id"),
      });
    }
  }

  _sendLessonStartedToAhoy() {
    if (!this.videoStarted) {
      this.ahoy.track("lesson_started", {
        lesson_id: this.data.get("lesson-id"),
        lesson_name: this.data.get("lesson-name"),
        course_name: this.data.get("course-name"),
        course_id: this.data.get("course-id"),
      });
    }
  }
}
