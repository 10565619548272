import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "formContainer",
    "form",
    "successContainer",
    "submitButton",
    "loadingIcon",
    "name",
    "email",
    "phoneNumber",
  ];

  submit(evt) {
    evt.preventDefault();

    this.loadingIconTarget.classList.remove("hidden");

    const formData = new FormData();
    formData.append("workshop_attendee[name]", this.nameTarget.value);
    formData.append("workshop_attendee[email]", this.emailTarget.value);
    formData.append(
      "workshop_attendee[phone_number]",
      this.phoneNumberTarget.value
    );

    Rails.ajax({
      url: this.formTarget.action,
      type: "POST",
      data: formData,
      success: (res) => {
        this.formContainerTarget.classList.add("hidden");
        this.successContainerTarget.classList.remove("hidden");
        this._submitEventToFacebook();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  handleInputChange(evt) {
    if (
      this.nameTarget.value != "" &&
      this.emailTarget.value != "" &&
      this.phoneNumberTarget.value != ""
    ) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.remove("opacity-25");
      this.submitButtonTarget.classList.remove("cursor-not-allowed");
    } else {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.add("opacity-25");
      this.submitButtonTarget.classList.add("cursor-not-allowed");
    }
  }

  _submitEventToFacebook() {
    fbq("track", "CompleteRegistration", { content_name: "Workshop" });
  }
}
