import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trigger", "hideable"];
  static values = { selected: String, hideByDefault: Boolean };

  connect() {
    if (JSON.parse(this.hideByDefaultValue)) {
      this._hideHideable(this.hideableTarget);
    }
  }

  handleTriggerChange(e) {
    console.log(this.hideableTarget);
    if (e.target.value == this.triggerTarget.dataset.toggleable) {
      this._showHideable(this.hideableTarget);
    } else {
      this._hideHideable(this.hideableTarget);
    }
  }

  _showHideable(el) {
    el.classList.remove("hidden");
  }

  _hideHideable(el) {
    el.classList.add("hidden");
  }
}
