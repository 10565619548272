import { Controller } from "stimulus";
import Chartkick from "chartkick";
import "chartkick/chart.js";

export default class extends Controller {
  static targets = ["container"];
  static values = { id: String };

  connect() {
    this.chart = Chartkick.charts[this.idValue].getChartObject();
    this._setChartGradient();
  }

  _setChartGradient() {
    var container = this.element;
    var ctx = this.chart.ctx;
    // Change chart font to IBM Plex Sans
    window.chart = this.chart;
    chart.legend.options.labels.fontColor = "#333";
    var gradient = ctx.createLinearGradient(0, 0, 0, container.clientHeight);
    gradient.addColorStop(1, "rgba(126, 89, 240, 0)");
    gradient.addColorStop(0.2, "rgba(126, 89, 240, 0.5)");
    gradient.addColorStop(0, "rgba(126, 89, 240, 1)");
    this.chart.data.datasets.forEach(function (dataset) {
      dataset.backgroundColor = gradient;
      dataset.borderColor = "#8864f5";
      dataset.pointBackgroundColor = "#7c56f0";
      dataset.pointBorderColor = "#7c56f0";
      // Font family
      dataset.fontFamily = "IBM Plex Sans";
    });
    this.chart.update();
  }

  _setChartOptions() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              const date = new Date(context[0].parsed.x);
              return date.toLocaleDateString("es-ES", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            },
          },
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "d", // This will only display the day number
            },
          },
          ticks: {
            font: {
              family: "'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif",
              size: 12,
            },
            maxRotation: 0,
            autoSkip: true,
            color: "#718096",
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            font: {
              family: "'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif",
              size: 12,
            },
            color: "#718096",
          },
          grid: {
            display: true,
          },
        },
      },
      elements: {
        line: {
          tension: 0.4,
        },
        point: {
          radius: 4,
          hoverRadius: 6,
        },
      },
    };

    this.chart.options = { ...this.chart.options, ...options };
    this.chart.update();
  }
}
