import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loadingIndicator", "checkIcon", "buttonText"];
  static values = { url: String, lessonId: String };

  create(event) {
    event.preventDefault();
    this._showLoadingIndicator();

    if (this.lessonIdValue) {
      const formData = new FormData();
      formData.append("lesson_id", this.lessonIdValue);

      Rails.ajax({
        url: this.urlValue,
        type: "POST",
        data: formData,
        success: (response) => {
          this._hideLoadingIndicator();
          this._setTextAsCompleted();

          if (response.completed_course) {
            location.href = response.redirect_url;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  _showLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove("hidden");
    this.checkIconTarget.classList.add("hidden");
  }

  _hideLoadingIndicator() {
    this.loadingIndicatorTarget.classList.add("hidden");
    this.checkIconTarget.classList.remove("hidden");
  }

  _setTextAsCompleted() {
    this.buttonTextTarget.innerText = "Completaste esta lección";
  }
}
