import { Controller } from "stimulus";

import Uppy from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import XHRUpload from "@uppy/xhr-upload";
import StatusBar from "@uppy/status-bar";

export default class extends Controller {
  static targets = ["fileField", "dropzoneUploadContainer", "thumbnail"];
  static values = { url: String };

  connect() {
    this.uppy = new Uppy({
      autoProceed: true,
      locale: {
        strings: {
          browse: "explora",
          dropHereOr: "Arrastra tus archivos aquí o %{browse}",
        },
      },
    });
    this.uppy
      .use(DragDrop, {
        target: this.dropzoneUploadContainerTarget,
        replaceTargetContent: true,
      })
      .use(StatusBar, {
        target: ".UppyInput-Progress",
        hideUploadButton: true,
        hideAfterFinish: false,
      })
      .use(ThumbnailGenerator, {
        thumbnailHeight: 120,
      })
      .use(XHRUpload, {
        endpoint: this.urlValue,
        formData: true,
      });

    this.uppy.on("upload-success", (file, response) => {
      const uploadedFileData = response.body["data"];
      this.fileFieldTarget.value = JSON.stringify(uploadedFileData);
    });

    this.uppy.on("thumbnail:generated", (file, preview) => {
      this.thumbnailTarget.querySelector("img").src = preview;
    });
  }
}
