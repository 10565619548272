import { Controller } from "stimulus";

export default class extends Controller{
  static targets = ["trigger", "modalContainer", "modal", "player"];

  handleModalClick(e){
    e.preventDefault();

    if(this._isModalOpen()){
      this.modalContainerTarget.classList.add("hidden");
      this.playerTarget.querySelector("video").pause();
    } else{
      this.modalContainerTarget.classList.remove("hidden");
      this.playerTarget.querySelector("video").play();
    }
  }

  handleKeyup(e){
    if(e.keyCode == 27){
      this.modalContainerTarget.classList.add("hidden");
      this.playerTarget.querySelector("video").pause();
    }
  }

  _isModalOpen(){
    return !this.modalContainerTarget.classList.contains("hidden")
  }
}