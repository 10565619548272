import { Controller } from "stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["arrow", "loadingIndicator"];
  static values = {
    url: String,
    checkoutMode: String,
    priceId: String,
    couponId: String,
    quantity: Number,
    isUserSignedIn: Boolean,
    cartId: String,
  };

  redirect(e) {
    e.preventDefault();
    const checkoutMode = this.checkoutModeValue;

    switch (checkoutMode) {
      case "subscription":
        this._createSubscriptionCheckoutSession();
        break;
      case "payment":
        this._createPaymentCheckoutSession();
        break;
    }
  }

  _createSubscriptionCheckoutSession() {
    const formData = new FormData();

    formData.append("price_id", this.priceIdValue);
    formData.append("quantity", this.quantityValue);
    formData.append("coupon_id", this.couponIdValue);

    this._showLoadingIndicator();

    Rails.ajax({
      url: this.urlValue,
      type: "POST",
      data: formData,
      success: (response) => {
        if (response.url) {
          location.href = response.url;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  // We define two different branches for the payment checkout session
  // if the user is buying a single product using the buy now button
  //
  _createPaymentCheckoutSession() {
    const oneClickCheckout = JSON.parse(this.data.get("oneClickCheckout"));

    if (oneClickCheckout) {
      // Handle the oneClickCheckout
      this._showLoadingIndicator();
      this._addItemToCartAndCreateCheckoutSession();
    } else {
      const formData = new FormData();
      formData.append("cart_id", this.cartIdValue);

      this._showLoadingIndicator();
      this._requestPaymentCheckoutSession(formData);
    }
  }

  _addItemToCartAndCreateCheckoutSession() {
    // We specify the endpoint that we don't want the server JS response
    // to show the slide over cart panel
    const cartFormData = new FormData();
    cartFormData.append("should_show_slide_over_panel", false);

    // Axios POST request to add the item to the cart
    // pass the authenticity token to axios
    axios.defaults.headers.common["X-CSRF-Token"] = Rails.csrfToken();
    axios.defaults.headers.common["Accept"] = "application/json";
    // Send the request as JSON
    axios.post(this.data.get("addToCartUrl"), cartFormData).then((response) => {
      const cartId = response.data.id;
      const formData = new FormData();
      formData.append("cart_id", cartId);
      this._requestPaymentCheckoutSession(formData);
    });
  }

  _requestPaymentCheckoutSession(formData) {
    Rails.ajax({
      url: this.urlValue,
      type: "POST",
      data: formData,
      success: (response) => {
        if (response.url) {
          location.href = response.url;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  _showLoadingIndicator() {
    this.arrowTarget.classList.add("hidden");
    this.loadingIndicatorTarget.classList.remove("hidden");
  }
}
