import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["text", "loadingIndicator"];

  handleClick() {
    this._showLoadingIndicator();
  }

  _showLoadingIndicator() {
    this.loadingIndicatorTarget.style.display = "block";
  }
}
