import {Controller} from "stimulus";

export default class extends Controller{
  static targets = ["countable", "count"];

  handleChange(e){
    this.setCharacterCount(e.target.value);
    this.handleCountState(e.target.value);
  }

  setCharacterCount(string){
    this.countTarget.innerHTML = `${string.length} caracteres`
  }

  handleCountState(string){
    if(string.length > this.countableTarget.dataset.maxLength){
      if(this.countTarget.classList.contains("text-orange-500")){
        this.countTarget.classList.remove("text-orange-500")
      }
      this.countTarget.classList.add("text-red-600")
    } else if(string.length < this.countableTarget.dataset.alertLength){
      if(this.countTarget.classList.contains("text-orange-500")){
        this.countTarget.classList.remove("text-orange-500")
      } else if(this.countTarget.classList.contains("text-red-600")){
        this.countTarget.classList.remove("text-red-600")
      }
    } else if(string.length > this.countableTarget.dataset.alertLength){
      if(this.countTarget.classList.contains("text-red-600")){
        this.countTarget.classList.remove("text-red-600");
        this.countTarget.classList.add("text-orange-500")
      } else{
        this.countTarget.classList.add("text-orange-500")
      }
    }
  }
}