import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contentBox", "toggleButton", "overlay"];

  toggle() {
    if (this.contentBoxTarget.dataset.status == "closed") {
      this.contentBoxTarget.classList.remove(
        "read-more-toggle-container-height"
      );
      this.contentBoxTarget.dataset.status = "open";
      this.contentBoxTarget.style.height = "100%";
      this.toggleButtonTarget.innerHTML = "Leer menos";
      this.overlayTarget.style.display = "none";
    } else {
      this.contentBoxTarget.style.height = "200px";
      this.contentBoxTarget.dataset.status = "closed";
      this.toggleButtonTarget.innerHTML = "Leer más";
      this.overlayTarget.style.display = "block";
    }
  }
}
