import { Controller } from "stimulus";

import videojs from "video.js";

import "video.js/dist/video-js.css";
import "@videojs/themes/fantasy/index.css";

export default class extends Controller {
  static targets = ["player"];

  initialize() {
    this.playerTargets.map((player) => {
      videojs(player, {
        controls: true,
        playbackRates: [0.5, 1, 2],
        autoplay: false,
        fluid: true,
      });
    });
  }
}
