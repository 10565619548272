import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "loadingContent"];
  static values = { url: String };

  delete(evt) {
    evt.preventDefault();
    this._showLoadingText();
    if (confirm("Are you sure you want to delete this resource?")) {
      Rails.ajax({
        url: this.urlValue,
        type: "delete",
        success: (data) => {
          this._hideLoadingText();
          this.element.remove();
        },
        error: (data) => {
          this._hideLoadingText;
        },
      });
    }
  }

  _showLoadingText() {
    this.contentTarget.classList.add("hidden");
    this.loadingContentTarget.classList.remove("hidden");
  }

  _hideLoadingText() {
    this.contentTarget.classList.remove("hidden");
    this.loadingContentTarget.classList.add("hidden");
  }
}
