import { Controller } from "stimulus";

import FroalaEditor from "froala-editor";
import Tribute from "tributejs";
import "tributejs/tribute.css";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    setTimeout(() => {
      this.editor = this.fieldTarget["data-froala.editor"];
      this.initializeTribute();
    }, 300);
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
  }

  initializeTribute() {
    let self = this;

    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "username",
      values: function (text, callback) {
        self._fetchUsers(text, (users) => callback(users));
      },
      selectTemplate: function (item) {
        return item.original.content;
      },
    });

    console.log(this.editor);
    this.tribute.attach(this.editor.el);
    // this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.editor.events.on(
      "keydown",
      function (e) {
        if (e.which == FroalaEditor.KEYCODE.ENTER && self.tribute.isActive) {
          return false;
        }
      },
      true
    );

    // this.editor.el.addEventListener("tribute-replaced", function(e){
    //   let mention = e.detail.item.original;
    //   self.editor.html.insert(mention.content, true)
    // })
  }

  _fetchUsers(text, callback) {
    fetch(`/usuarios.json?query=${text}`)
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch((error) => callback([]));
  }

  replaced(e) {
    let mention = e.detail.item.original;

    self.editor.html.insert(mention.content, true);
    self.editor.html.insert("<span></span>", false);
  }

  _pasteHtml(html, startPos, endPos) {
    this.editor.selection.setAtEnd();
    for (var i = 0; i < endPos - startPos; i++) {
      this.editor.cursor.backspace();
    }
  }
}
