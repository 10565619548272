import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["panel"];

  connect() {
    this.panelTarget.classList.add("hidden");
  }

  showPanel() {
    if (this._isPanelClosed()) {
      this.panelTarget.classList.remove("hidden");
    }
  }

  hidePanel() {
    if (this._isPanelOpen()) {
      this.panelTarget.classList.add("hidden");
    }
  }

  handleKeyup(e) {
    if (e.keyCode == 27 && this._isPanelOpen()) {
      this.hidePanel();
    }
  }

  _isPanelOpen() {
    return !this.panelTarget.classList.contains("hidden");
  }

  _isPanelClosed() {
    return this.panelTarget.classList.contains("hidden");
  }
}
