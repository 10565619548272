import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "days", "hours", "minutes", "seconds"];

  connect() {
    if(this._getDateInterval() > 0){
      this._setInitialData();

      setInterval(() => {
        const difference = this._getDateInterval();

        const [days, hours, minutes, seconds] = this._getCountdownValues(difference);

        this._setElementsInnerHtml(days, hours, minutes, seconds)
      }, 1000);
    } else {
      this._setElementsInnerHtml(0, 0, 0, 0)
    }

  }

  _setInitialData() {
    const difference = this._getDateInterval();

    const [days, hours, minutes, seconds] = this._getCountdownValues(difference);

    this._setElementsInnerHtml(days, hours, minutes, seconds)
  }

  _getDateInterval() {
    return new Date(this.data.get("date")).getTime() - new Date().getTime();
  }

  _getCountdownValues(difference) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  }

  _setElementsInnerHtml(days, hours, minutes, seconds) {
    this.daysTarget.innerHTML = days;
    this.hoursTarget.innerHTML = hours;
    this.minutesTarget.innerHTML = minutes;
    this.secondsTarget.innerHTML = seconds;
  }
}