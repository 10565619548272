import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["title", "description", "slug", "slugField"];

  handleChange(e) {
    let target = this.targets.find(e.target.dataset.changeable);

    if (target == this.titleTarget) {
      target.innerHTML = e.target.value;
      this.slugTarget.querySelector("span").innerHTML = this.stringToSlug(
        e.target.value.replace(" - Sonipedia", "")
      );
      this.slugFieldTarget.value = this.stringToSlug(
        e.target.value.replace(" - Sonipedia", "")
      );
    } else if (target == this.descriptionTarget) {
      if (e.target.value.length > 0) {
        target.innerHTML = e.target.value;
      } else {
        target.innerHTML = target.dataset.defaultValue;
      }
    } else {
      target.querySelector("span").innerHTML = e.target.value;
    }
  }

  stringToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  }
}
