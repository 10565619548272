import Splide from "@splidejs/splide";

document.addEventListener("turbolinks:load", function () {
  const nodes = document.querySelectorAll("[data-behavior=splide]");
  if (nodes) {
    Array.from(nodes).map((node) => {
      new Splide(node, {
        perPage: node.dataset.elementsPerPageDesktop || 1,
        gap: 20,
        breakpoints: {
          1024: {
            perPage: node.dataset.elementsPerPageTablet || 1,
          },
          768: {
            perPage: node.dataset.elementsPerPageTabletSmall || 1,
          },
          400: {
            perPage: node.dataset.elementsPerPageMobile || 1,
          },
        },
      }).mount();
    });
  }
});
