import { Controller } from "stimulus";
import { Notyf } from "notyf";

export default class extends Controller {
  static values = { url: String, requestMethod: String };
  static targets = ["loadingSpinner"];

  connect() {
    console.log("Connecting to the remote request");
  }

  makeRequest(evt) {
    this.loadingSpinnerTarget.classList.remove("hidden");
    evt.preventDefault();

    Rails.ajax({
      url: this.urlValue,
      type: this.requestMethodValue,
      success: (res) => {
        this.loadingSpinnerTarget.classList.add("hidden");
        this._showSuccessMessage(res);
      },
      error: (err) => {
        this.loadingSpinnerTarget.classList.add("hidden");
        this._showErrorMessage(err);
      },
    });
  }

  _showSuccessMessage(res) {
    let notyf = new Notyf({
      position: { x: "right", y: "top", duration: 2000 },
    });
    notyf.success(res.message);
  }
}
