import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trigger", "inlineField"];

  handleTriggerChange(e) {
    switch (e.target.value) {
      case "vimeo":
        this.inlineFieldTarget.innerHTML = "vimeo.com/";
        this.inlineFieldTarget.classList.remove(
          "text-indigo-500",
          "text-red-500"
        );
        this.inlineFieldTarget.classList.add("text-blue-500");
        break;
      case "wistia":
        this.inlineFieldTarget.innerHTML = "wistia.com/medias/";
        this.inlineFieldTarget.classList.remove(
          "text-blue-500",
          "text-red-500"
        );
        this.inlineFieldTarget.classList.add("text-indigo-500");
        break;
      case "youtube":
        this.inlineFieldTarget.innerHTML = "youtube.com/watch?v=";
        this.inlineFieldTarget.classList.remove(
          "text-blue-500",
          "text-indigo-500"
        );
        this.inlineFieldTarget.classList.add("text-red-500");
        break;
    }
  }
}
