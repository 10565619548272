import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["links", "template", "field"];

  connect() {
    this.data.get("wrapperClass");
  }

  addAssociation(event){
    event.preventDefault();
    if(this.data.get("poll-nested-form")){
      if(this.fieldTargets.length >= 10){
        return
      }
      let content = this.templateTarget.innerHTML.replace("Opción 1", `Opción ${this.fieldTargets.length + 1}`).replace(/NEW_RECORD/g, new Date().getTime());
      this.linksTarget.insertAdjacentHTML("beforebegin", content)
    } else{
      let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
      this.linksTarget.insertAdjacentHTML("beforebegin", content)
    }
  }

  removeAssociation(event){
    event.preventDefault();
    let wrapper = event.target.closest(".nested-fields")
    if(wrapper.dataset.newRecord == "true"){
      wrapper.remove();
    } else{
      wrapper.querySelector("input[name*='_destroy'").value = 1;
      wrapper.style.display = "none";
    }
  }
}