import { Controller } from "stimulus";

import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    url: String,
    group: String,
    itemName: String,
    listId: Number,
  };
  static targets = [
    "list",
    "skeletonTemplate",
    "filtersTemplate",
    "paginationTemplate",
  ];

  connect() {
    this.currentPage = 1;

    this.element.insertAdjacentHTML(
      "afterbegin",
      this.skeletonTemplateTarget.innerHTML
    );
    this._fetchItems();
  }

  loadMore(evt) {
    evt.preventDefault();
    this.currentPage += 1;
    this._appendItems();
  }

  _appendItems() {
    let that = this;
    const searchParams = new URLSearchParams(window.location.search);

    Rails.ajax({
      url: `${this.urlValue}?page=${that.currentPage}&${searchParams}`,
      type: "GET",
      success: function (response) {
        that.listTarget.insertAdjacentHTML("beforeend", response);
        that.element.querySelector("[data-behavior=list-pagination]").remove();
        that._connectSortable();
        that._addPagination();
      },
    });
  }

  _fetchItems() {
    let that = this;
    const searchParams = new URLSearchParams(window.location.search);

    Rails.ajax({
      url: `${this.urlValue}?${searchParams}`,
      type: "GET",
      success: function (response) {
        that._populateItems(response);
      },
    });
  }

  _populateItems(response) {
    this.listTarget.innerHTML = response;
    this._connectSortable();
    this._addPagination();
  }

  _connectSortable() {
    new Sortable(this.element, {
      group: this.groupValue,
      dragClass: "dragging",
      onStart: this._onStart.bind(this),
      onEnd: this._onEnd.bind(this),
      onAdd: this._onAdd.bind(this),
      onMove: this._onMove.bind(this),
      animation: 250,
    });
  }

  _addPagination() {
    if (this.hasPaginationTemplateTarget) {
      this.element.insertAdjacentHTML(
        "beforeend",
        this.paginationTemplateTarget.innerHTML
      );
    }
  }

  _onStart(evt) {
    // console.log(evt.item);
    const originItem = evt.item.querySelector("[data-behavior='lead-card']");
    originItem.classList.add("slight-rotate");
  }

  _onMove(evt) {
    // console.log(ev.draggedRect);
  }

  _onAdd(evt) {
    // console.log(ev);
  }

  _onEnd(evt) {
    // The 'from' value from the event returns the list from which the item was moved
    // We should be able to access the name of the list from its dataset
    // console.log("From: ", evt.from.dataset.listId);
    // The 'to' value from the event returns the list to which the item was moved
    // console.log("To: ", evt.to.dataset.listId);
    // console.log("Item: ", evt.item.dataset.itemId);
    // The 'newIndex' value from the event returns the new index of the item in the list
    // console.log("New Index: ", evt.newIndex);
    // The 'oldIndex' value from the event returns the old index of the item in the list
    // console.log("Old Index: ", evt.oldIndex);

    console.log(evt.from);

    const originListId = evt.from.dataset.listId;
    const destinationListId = evt.to.dataset.listId;
    const item = evt.item;
    const itemCard = item.querySelector("[data-behavior='lead-card']");
    const position = evt.newIndex + 1;

    itemCard.classList.remove("slight-rotate");

    Rails.ajax({
      url: item.dataset.url,
      type: "PATCH",
      data: `item_id=${item.dataset.itemId}&position=${position}&destination_list_id=${destinationListId}`,
      success: function (response) {
        const originListLeadCountEl = document.querySelector(
          `[data-behavior='${originListId}-lead-count']`
        );
        const destinationListLeadCountEl = document.querySelector(
          `[data-behavior='${destinationListId}-lead-count']`
        );

        if (originListId == destinationListId) return;

        const originListLeadCount = parseInt(originListLeadCountEl.textContent);
        const destinationListLeadCount = parseInt(
          destinationListLeadCountEl.textContent
        );

        originListLeadCountEl.textContent = originListLeadCount - 1;
        destinationListLeadCountEl.textContent = destinationListLeadCount + 1;
      },
    });
  }
}
