import FroalaEditor from "froala-editor";

FroalaEditor.POPUP_TEMPLATES["customAudioPopup.popup"] =
  "[_TABS_][_CUSTOM_LAYER_]";

FroalaEditor.PLUGINS.customAudioPopup = function (editor) {
  var $popup;

  function initPopup() {
    var template = {
      tabs: `
        <div class="fr-tabs">
          <button class="fr-command fr-tab fr-active" data-cmd="audioUpload" type="button" tabIndex="-1" data-type="upload">Upload</button>
          <button class="fr-command fr-tab" data-cmd="audioUrl" type="button" tabIndex="-1" data-type="url">By URL</button>
        </div>
      `,
      custom_layer: `
        <div class="fr-audio-layer fr-layer fr-active">
          <div class="fr-audio-upload-layer fr-layer fr-active">
            <div class="flex items-center space-x-2 mb-3 hidden" data-behavior="blog-audio-uploadloading">
              <p class="text-sm text-zinc-600">Subiendo archivo...</p>
              <i class="fa-regular fa-sync fa-spin text-zinc-600"></i>
            </div>
            <input type="file" accept="audio/*" class="fr-audio-upload">
          </div>
          <div class="fr-audio-by-url-layer fr-layer">
            <strong>Audio by URL</strong>
            <br>
            <div class="flex flex-col space-y-3">
              <input type="text" placeholder="https://soundcloud.com/..." class="fr-audio-by-url" tabIndex="1" name="url">
              <input type="text" placeholder="Nombre de la pista" class="fr-audio-by-name" tabIndex="1" name="name">
              <button class="fr-command fr-submit" data-cmd="audioInsertByUrl" type="button" tabIndex="2">Insert</button>
            </div>
          </div>
        </div>
      `,
    };

    $popup = editor.popups.create("customAudioPopup.popup", template);

    _initEvents();
    return $popup;
  }

  function showPopup() {
    if (!$popup) $popup = initPopup();

    editor.popups.setContainer("customAudioPopup.popup", editor.$tb);

    var $btn = editor.$tb.find('.fr-command[data-cmd="insertAudio"]');
    var left = $btn.offset().left + $btn.outerWidth() / 2;
    var top =
      $btn.offset().top +
      (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

    editor.popups.show("customAudioPopup.popup", left, top, $btn.outerHeight());
  }

  function hidePopup() {
    if ($popup) {
      editor.popups.hide("customAudioPopup.popup");
    }
  }

  function audioUpload() {
    console.log("audioUpload");
    if ($popup) {
      document
        .querySelector(".fr-tabs")
        .querySelector("[data-type='url']")
        .classList.remove("fr-active");
      document
        .querySelector(".fr-tabs")
        .querySelector("[data-type='upload']")
        .classList.add("fr-active");
      $popup.find(".fr-audio-upload-layer").addClass("fr-active");
      $popup.find(".fr-audio-by-url-layer").removeClass("fr-active");
    }
  }

  function audioByUrl() {
    if ($popup) {
      document
        .querySelector(".fr-tabs")
        .querySelector("[data-type='upload']")
        .classList.remove("fr-active");
      document
        .querySelector(".fr-tabs")
        .querySelector("[data-type='url']")
        .classList.add("fr-active");
      $popup.find(".fr-audio-by-url-layer").addClass("fr-active");
      $popup.find(".fr-audio-upload-layer").removeClass("fr-active");
      $popup.find(".fr-audio-by-url").focus();
    }
  }

  function insertAudioByUrl() {
    if ($popup) {
      var url = $popup.find(".fr-audio-by-url").val();
      var name = $popup.find(".fr-audio-by-name").val();
      if (url.length > 0) {
        editor.html.insert(`[audio url="${url}" track_name="${name}"][/audio]`);
        hidePopup();
      }
    }
  }

  async function insertAudioFile(file) {
    console.log(file);
    if (file && file.type.indexOf("audio") !== -1) {
      try {
        const audioUploadLoading = document.querySelector(
          "[data-behavior='blog-audio-uploadloading']"
        );
        audioUploadLoading.classList.remove("hidden");
        // Get the presign data from Shrine
        const presignResponse = await fetch(
          `/s3/params?type=image&filename${file.name}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
          }
        );
        const presignData = await presignResponse.json();

        // Prepare the form data for upload
        const formData = new FormData();
        Object.entries(presignData.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        // Upload the file
        const uploadResponse = await fetch(presignData.url, {
          method: "POST",
          body: formData,
        });

        if (uploadResponse.ok) {
          // File uploaded successfully, now send the data to your server
          console.log(uploadResponse);
          const url = `${uploadResponse.url}${presignData.fields.key}`;
          const trackName = file.name;

          editor.html.insert(
            `[audio url="${url}" track_name="${trackName}"][/audio]`
          );
          hidePopup();
        } else {
          throw new Error("Failed to upload file to S3");
        }
      } catch (error) {
        console.error("Error uploading audio file:", error);
        // Handle the error (e.g., show an error message to the user)
      }
    }
  }

  editor.events.on(
    "toolbar.esc",
    function () {
      if ($popup && editor.popups.isVisible("customAudioPopup.popup")) {
        editor.popups.hide("customAudioPopup.popup");
        return false;
      }
    },
    true
  );

  editor.events.on("mousedown window.mousedown", function () {
    if ($popup && editor.popups.isVisible("customAudioPopup.popup")) {
      hidePopup();
    }
  });

  editor.events.on("window.keydown", function (e) {
    if (
      e.which == FroalaEditor.KEYCODE.ESC &&
      $popup &&
      editor.popups.isVisible("customAudioPopup.popup")
    ) {
      hidePopup();
      return false;
    }
  });

  function _initEvents() {
    editor.events.$on($popup, "change", ".fr-audio-upload", function () {
      var files = this.files;
      if (files && files.length) {
        insertAudioFile(files[0]);
      }
    });

    editor.events.$on($popup, "click", function (e) {
      e.stopPropagation();
    });
  }

  return {
    showPopup: showPopup,
    hidePopup: hidePopup,
    audioUpload: audioUpload,
    audioByUrl: audioByUrl,
    insertAudioByUrl: insertAudioByUrl,
  };
};

FroalaEditor.DefineIcon("insertAudioIcon", {
  NAME: "file-audio",
});

FroalaEditor.RegisterCommand("insertAudio", {
  title: "Insert Audio",
  icon: "insertAudioIcon",
  undo: false,
  focus: false,
  plugin: "customAudioPopup",
  callback: function () {
    this.customAudioPopup.showPopup();
  },
});

FroalaEditor.RegisterCommand("audioUpload", {
  title: "Upload Audio",
  undo: false,
  focus: false,
  callback: function () {
    this.customAudioPopup.audioUpload();
  },
});

FroalaEditor.RegisterCommand("audioUrl", {
  title: "Audio by URL",
  undo: false,
  focus: false,
  callback: function () {
    this.customAudioPopup.audioByUrl();
  },
});

FroalaEditor.RegisterCommand("audioInsertByUrl", {
  title: "Insert Audio by URL",
  undo: false,
  focus: false,
  callback: function () {
    this.customAudioPopup.insertAudioByUrl();
  },
});

function removeFileFormat(filename) {
  const parts = filename.split(".");
  if (parts.length > 1) {
    return parts.slice(0, -1).join(".");
  }
  return filename;
}
