import {Controller} from "stimulus";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller{

  initialize(){
    tippy(this.element, {
      content: this.data.get("content"),
      allowHTML: true,
      theme: this.data.get("theme") || "",
      placement: this.data.get("placement") || "top",
    }) 
  }
}