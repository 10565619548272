import { Controller } from "stimulus";
import FroalaEditor from "froala-editor";

import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";

export default class extends Controller {
  disconnect() {
    if (this.froala) {
      this.froala.destroy();
    }
  }

  connect() {
    this.froala = new FroalaEditor(this.element, {
      key: "aLF3c1C10C5E6C2E3F2C-7wjhnxlnbmA3md1C-13mD6F5F4H4E1B9A8C3C4F5==",
      placeholderText: this.element.dataset.placeholder || "Tu respuesta...",
      width: this.element.dataset.width,
      height: this.element.dataset.height || 300,
      attribution: false,
      emoticonsUseImage: false,
      linkAttributes: {
        rel: "ugc",
      },
      linkAlwaysBlank: true,
      linkAlwaysNoFollow: true,
      linkNoOpener: false,
      linkNoReferrer: false,
      linkText: true,
      toolbarButtons: {
        moreText: {
          buttons: ["bold", "italic", "underline"],
        },
        moreRich: {
          buttons: ["insertLink", "emoticons"],
        },
        moreMisc: {
          buttons: [
            "audio",
            "undo",
            "redo",
            "fullscreen",
            "print",
            "spellChecker",
            "selectAll",
          ],
        },
      },
    });
  }
}
