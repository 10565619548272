import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: "shared",
      animation: 150,
      onEnd: this.onDragEnd.bind(this),
      swapTreshold: 0.65,
    });
  }

  onDragEnd(event) {
    let chapter_id = event.item.dataset.chapterId;
    let id = event.item.dataset.id;
    let url = this.urlValue;
    let mapUrl = { chapter_id: chapter_id, id: id };

    if (mapUrl.chapter_id) {
      url = url.replace(/chapter_id|id/gi, (matched) => {
        return mapUrl[matched];
      });
    } else {
      url = url.replace(":id", id);
    }

    let data = new FormData();

    data.append("position", event.newIndex + 1);

    Rails.ajax({
      url: url,
      type: "PATCH",
      data: data,
    });
  }
}
