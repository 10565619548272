import { Controller } from "stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["button"];
  static values = { confirmationText: String, url: String };

  static debounces = [
    "keydown",
    {
      name: "verifyValue",
      wait: 200,
    },
  ];

  connect() {
    useDebounce(this);
    this.buttonTarget.disabled = true;
  }

  verifyValue(event) {
    const textMatches = event.target.value === this.confirmationTextValue;

    textMatches ? this._enableButton() : this._disableButton();
  }

  submit(event) {
    const url = this.urlValue;
    Rails.ajax({
      url: url,
      type: "DELETE",
      success: function (response) {
        console.log(response);
      },
      error: function (error) {
        console.log(error);
      },
    });
  }

  _enableButton() {
    this.buttonTarget.disabled = false;
    this.buttonTarget.classList.remove(
      "bg-gray-500",
      "hover:bg-gray-600",
      "cursor-not-allowed"
    );
    this.buttonTarget.classList.add("bg-indigo-700");
  }

  _disableButton() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.remove("bg-indigo-700");
    this.buttonTarget.classList.add(
      "bg-gray-500",
      "hover:bg-gray-600",
      "cursor-not-allowed"
    );
  }
}
