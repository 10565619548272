import { Controller } from "stimulus";

export default class extends Controller{
  static targets = ["passwordField", "iconOn", "iconOff"];

  toggle(e){
    if(this.iconOffTarget.classList.contains("hidden")){
      this.passwordFieldTarget.setAttribute("type", "text");
    } else {
      this.passwordFieldTarget.setAttribute("type", "password");
    }

    this.iconOnTarget.classList.toggle("hidden");
    this.iconOffTarget.classList.toggle("hidden");
  }
}