import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trigger", "hideable"];
  static values = { nonHideOption: String, displayByDefault: String };

  connect() {
    this.defaultHideableElement = this.hideableTargets.filter((t) => {
      return t.dataset.option != this.displayByDefaultValue;
    })[0];

    this.defaultHideableElement.classList.toggle("hidden");
  }

  handleSelectChange(e) {
    // Handle the case where we don't want to hide any of the hideable elements
    if (e.target.value == this.nonHideOptionValue) {
      this.hideableTargets.map((t) => {
        t.classList.remove("hidden");
      });
      return;
    }

    let elementToShow = this.hideableTargets.filter((t) => {
      return t.dataset.option.toLowerCase() == e.target.value;
    })[0];
    let elementToHide = this.hideableTargets.filter((t) => {
      return t.dataset.option.toLowerCase() != e.target.value;
    })[0];

    elementToShow.classList.remove("hidden");
    elementToHide.classList.add("hidden");
  }
}
