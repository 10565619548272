import { Controller } from "stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = [
    "container",
    "notifications",
    "notificationCount",
    "markAsRead",
    "markAsReadTemplate",
    "emptyState",
    "emptyStateTemplate",
  ];

  static values = { adminOnly: Boolean };

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "NotificationsChannel", id: this.data.get("username-slug") },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {
    consumer.subscriptions.remove(this.subscription);
  }

  _received(data) {
    // We receive the data from the server and we can tell if the notification is admin only.
    // If the notification is admin only but the current HTML rendered doesn't have the adminOnlyValue set to
    // true, we don't want to render the notification.
    if (data.admin_only && !this.adminOnlyValue) {
      return;
    } else {
      this._insertNotification(data);
      this._updateNotificationCount();
      this._removeEmptyStateIfNecessary();
      this._injectMarkAsReadIfNecessary();
    }
  }

  markAllAsRead(e) {
    e.preventDefault();
    const url = this.data.get("mark-as-read-url");
    let that = this;

    Rails.ajax({
      url: url,
      type: "POST",
      success: function (response) {
        that._removeMarkAsReadIfNecessary();
        that._emptyNotificationsCountAndBody();
      },
    });
  }

  _insertNotification(data) {
    this.notificationsTarget.insertAdjacentHTML("beforeend", data.html);
  }

  _injectMarkAsReadIfNecessary() {
    if (!this.hasMarkAsReadTarget) {
      this.containerTarget.insertAdjacentHTML(
        "beforeend",
        this.markAsReadTemplateTarget.innerHTML
      );
    }
  }

  _removeEmptyStateIfNecessary() {
    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.remove();
    }
  }

  _removeMarkAsReadIfNecessary() {
    if (this.hasMarkAsReadTarget) {
      this.markAsReadTarget.remove();
    }
  }

  _emptyNotificationsCountAndBody() {
    this.notificationCountTarget.innerHTML = 0;
    this.notificationsTarget.innerHTML =
      this.emptyStateTemplateTarget.innerHTML;
  }

  _updateNotificationCount() {
    this.notificationCountTarget.innerHTML =
      parseInt(this.notificationCountTarget.innerHTML) + 1;
  }
}
