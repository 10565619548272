import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option"];

  vote(e) {
    e.preventDefault();

    let data = new FormData();
    data.append("poll_answer[poll_id]", e.target.dataset.pollId);
    data.append("poll_answer[poll_option_id]", e.target.dataset.pollOptionId);

    const url = this.optionTarget.dataset.url;

    Rails.ajax({
      type: "POST",
      method: "JS",
      url: url,
      data: data,
    });
  }
}
