import "../../insert_audio_popup.js";

import { Controller } from "stimulus";
import FroalaEditor, { POWERED_BY } from "froala-editor";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/inline_class.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/url.min.js";

export default class extends Controller {
  connect() {
    this.editor = new FroalaEditor(this.element, {
      key: "aLF3c1C10C5E6C2E3F2C-7wjhnxlnbmA3md1C-13mD6F5F4H4E1B9A8C3C4F5==",
      heightMin: `${this.element.dataset.height}px` || "450px",
      attribution: false,
      placeholderText: "Empieza a escribir...",
      height: this.element.dataset.height || 300,
      htmlAllowedTags: this.htmlAllowedTagList,
      htmlAllowedAttrs: this.htmlAllowedAttributeList,
      iconsTemplate: "font_awesome_5r",
      linkAttributes: {
        rel: "follow",
      },
      linkAlwaysBlank: true,
      linkAlwaysNoFollow: false,
      linkNoOpener: false,
      linkNoReferrer: false,
      linkText: true,
      pastePlain: true,
      toolbarButtons: {
        moreText: {
          buttons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "subscript",
            "superscript",
            "fontFamily",
            "fontSize",
            "textColor",
            "backgroundColor",
            "inlineClass",
            "inlineStyle",
            "clearFormatting",
            "insertVideo",
          ],
          buttonsVisible: 4,
        },
        moreParagraph: {
          buttons: [
            "alignLeft",
            "alignCenter",
            "alignRight",
            "alignJustify",
            "formatOL",
            "formatUL",
            "paragraphFormat",
            "paragraphStyle",
            "inlineClass",
            "lineHeight",
            "outdent",
            "indent",
            "quote",
          ],
        },
        moreRich: {
          buttons: [
            "insertLink",
            "insertImage",
            "insertAudio",
            "insertTable",
            "insertHR",
            "selectAll",
          ],
          buttonsVisible: 6,
        },
      },
      inlineClasses: {
        "froala__testimonial-highlight": "Testimonial Highlight",
      },
      imageEditButtons: [
        "imageReplace",
        "imageAlign",
        "imageCaption",
        "imageRemove",
        "|",
        "imageLink",
        "linkOpen",
        "linkEdit",
        "linkRemove",
        "-",
        "imageDisplay",
        "imageStyle",
        "imageAlt",
        "imageSize",
      ],
      imageStyles: {
        "article__image--full-width": "full-width",
        "article__image--rounded": "rounded",
        "article__image--shadow": "shadow",
      },
      imageUploadURL: this.element.dataset.uploadUrl,
      imageUploadParam: this.element.dataset.uploadParam,
      imageUploadParams: {
        authenticity_token: Rails.csrfToken(),
        imageable_type: this.element.dataset.imageableType,
      },
      events: {
        "image.loaded": function ($img) {
          console.log($img);
          $img.removeAttr("image_data");
        },
      },
    });
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  get htmlAllowedTagList() {
    return [
      "a",
      "abbr",
      "address",
      "area",
      "article",
      "aside",
      "audio",
      "b",
      "base",
      "bdi",
      "bdo",
      "blockquote",
      "br",
      "button",
      "canvas",
      "caption",
      "cite",
      "code",
      "col",
      "colgroup",
      "datalist",
      "dd",
      "del",
      "details",
      "dfn",
      "dialog",
      "div",
      "dl",
      "dt",
      "em",
      "embed",
      "fieldset",
      "figcaption",
      "figure",
      "footer",
      "form",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "header",
      "hgroup",
      "hr",
      "i",
      "iframe",
      "img",
      "input",
      "ins",
      "kbd",
      "keygen",
      "label",
      "legend",
      "li",
      "link",
      "main",
      "map",
      "mark",
      "menu",
      "menuitem",
      "meter",
      "nav",
      "noscript",
      "object",
      "ol",
      "optgroup",
      "option",
      "output",
      "p",
      "param",
      "path",
      "pre",
      "progress",
      "queue",
      "rp",
      "rt",
      "ruby",
      "s",
      "samp",
      "script",
      "style",
      "section",
      "select",
      "small",
      "source",
      "span",
      "strike",
      "strong",
      "sub",
      "summary",
      "sup",
      "svg",
      "table",
      "tbody",
      "td",
      "textarea",
      "tfoot",
      "th",
      "thead",
      "time",
      "title",
      "tr",
      "track",
      "u",
      "ul",
      "var",
      "video",
      "wbr",
    ];
  }

  get htmlAllowedAttributeList() {
    return [
      "accept",
      "accept-charset",
      "accesskey",
      "action",
      "align",
      "allowfullscreen",
      "allowtransparency",
      "alt",
      "aria-.*",
      "async",
      "autocomplete",
      "autofocus",
      "autoplay",
      "autosave",
      "background",
      "bgcolor",
      "border",
      "charset",
      "cellpadding",
      "cellspacing",
      "checked",
      "cite",
      "class",
      "color",
      "cols",
      "colspan",
      "content",
      "contenteditable",
      "contextmenu",
      "controls",
      "coords",
      "data",
      "data-.*",
      "datetime",
      "default",
      "defer",
      "dir",
      "dirname",
      "disabled",
      "download",
      "draggable",
      "dropzone",
      "enctype",
      "for",
      "form",
      "formaction",
      "frameborder",
      "headers",
      "height",
      "hidden",
      "high",
      "href",
      "hreflang",
      "http-equiv",
      "icon",
      "id",
      "ismap",
      "itemprop",
      "keytype",
      "kind",
      "label",
      "lang",
      "language",
      "list",
      "loop",
      "low",
      "max",
      "maxlength",
      "media",
      "method",
      "min",
      "mozallowfullscreen",
      "multiple",
      "muted",
      "name",
      "novalidate",
      "open",
      "optimum",
      "pattern",
      "ping",
      "placeholder",
      "playsinline",
      "poster",
      "preload",
      "pubdate",
      "radiogroup",
      "readonly",
      "rel",
      "required",
      "reversed",
      "rows",
      "rowspan",
      "sandbox",
      "scope",
      "scoped",
      "scrolling",
      "seamless",
      "selected",
      "shape",
      "size",
      "sizes",
      "span",
      "src",
      "srcdoc",
      "srclang",
      "srcset",
      "step",
      "summary",
      "spellcheck",
      "style",
      "tabindex",
      "target",
      "title",
      "type",
      "translate",
      "usemap",
      "value",
      "valign",
      "webkitallowfullscreen",
      "width",
      "wrap",
      "stroke-line-cap",
      "stroke-line-join",
      "xmlns",
      "viewBox",
      "fill",
      "stroke",
      "stroke-width",
      "d",
      "cx",
      "cy",
      "r",
      "rx",
      "ry",
      "transform",
      "x",
      "x1",
      "x2",
      "cy",
      "x1",
      "y1",
      "x2",
      "y2",
      "data-audio-player-target",
      "data-action",
    ];
  }
}
