document.addEventListener("turbolinks:load", () => {
  const counters = document.querySelectorAll(
    "[data-behavior=animated-counter]"
  );

  counters.forEach(function (counter) {
    if (!counter) return;

    const startValue = parseInt(counter.dataset.startValue, 10);
    const endValue = parseInt(counter.dataset.endValue, 10);
    const duration = parseInt(counter.dataset.duration, 10) || 1500; // Duration of the animation in milliseconds
    const easing = (t) => t * (2 - t); // Simple ease-out function

    const frameDuration = 1000 / 60;
    let currentFrame = 0;
    const totalFrames = Math.round(duration / frameDuration);
    const counterSpan = counter.querySelector(".counter-value");

    const animateCount = () => {
      if (currentFrame < totalFrames) {
        currentFrame++;
        const progress = currentFrame / totalFrames;
        const easedProgress = easing(progress);

        const currentCount = Math.round(
          startValue + (endValue - startValue) * easedProgress
        );
        counterSpan.textContent = currentCount;

        requestAnimationFrame(animateCount);
      } else {
        counterSpan.textContent = endValue; // Ensure it ends at the exact end value
      }
    };

    // Intersection Observer to trigger the animation when visible
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            requestAnimationFrame(animateCount);
            observer.unobserve(counter); // Stop observing once animation starts
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    observer.observe(counter);
  });
});
