import LocalTime from "local-time"

const setLocalTime = () => {
  LocalTime.config.i18n["es"] = {
    date: {
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      abbrDayNames: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      monthNames: ["Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"],
      yesterday: "ayer",
      today: "hoy",
      tomorrow: "mañana",
      on: "en",
      formats: {
        default: "%b %e del %Y",
        thisYear: "%b %e"
      }
    },
    time: {
      am: "am",
      pm: "pm",
      singular: "un {time}",
      singularAn: "una {time}",
      elapsed: "hace {time}",
      second: "segundo",
      seconds: "segundos",
      minute: "minute",
      minutes: "minutes",
      hour: "hour",
      hours: "hours",
      formats: {
        default: "%l:%M%P"
      }
    },
    datetime: {
      at: "{date} at {time}",
      formats: {
        default: "%B %e, %Y a las %l:%M%P %Z"
      }
    }
  }

  LocalTime.config.locale = "es";
  LocalTime.start();
}

export default setLocalTime;