import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field"];
  static values = { url: String };

  handleToggle(e) {
    e.preventDefault();
    let param = `${this.element.dataset.model}[${this.element.dataset.field}]`;

    let form = new FormData();

    form.append(param, this.fieldTarget.value);
    Rails.ajax({
      type: "patch",
      url: this.urlValue,
      data: form,
    });
  }
}
