import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "dropzone",
    "dropzoneText",
    "fileInput",
    "okIcon",
    "csvRows",
  ];

  onDragOver(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.add("bg-gray-200");
  }

  onDragLeave(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.remove("bg-gray-200");
  }

  onDrop(event) {
    event.preventDefault();
    // Remove the visual feedback
    this.dropzoneTarget.classList.remove("bg-gray-200");

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.dropzoneTextTarget.classList.add("hidden");
      this.fileInputTarget.files = files;
      // Optionally, you can display the name of the file
      this._displayFileName(files[0]);
      this._showOkIcon();

      if (files[0].type == "text/csv") {
        const reader = new FileReader();
        reader.onload = (event) => this._parseCSV(event.target.result);
        reader.readAsText(files[0]);
      }
    }
  }

  _displayFileName(file) {
    const fileName = file.name;
    const fileNameDisplay = document.createElement("p");
    fileNameDisplay.textContent = `${fileName} - ${this._formatFileSize(
      file.size
    )}`;
    this.dropzoneTarget.appendChild(fileNameDisplay);
  }

  _formatFileSize(bytes) {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;

    // If the size is more than 1 MB, display it in MB; otherwise, display in KB
    if (megabytes > 1) {
      return `${megabytes.toFixed(2)} MB`;
    } else {
      return `${kilobytes.toFixed(2)} KB`;
    }
  }

  _parseCSV(csvData) {
    const lines = csvData.split("\n");

    const csvRowCount = document.createElement("p");
    // We substract one because the first line is the header
    csvRowCount.textContent = `Filas en el CSV: ${lines.length - 1}`;
    this.csvRowsTarget.appendChild(csvRowCount);
  }

  _showOkIcon() {
    this.okIconTarget.classList.remove("hidden");
  }
}
