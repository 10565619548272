import {Controller} from "stimulus";
import swal from "sweetalert";
import videojs from 'video.js';

import 'video.js/dist/video-js.css';
import '@videojs/themes/fantasy/index.css';

export default class extends Controller {
  static targets = ['player', 'playerProgress'];

  initialize() {
    this.videoPlayer = videojs(this.playerTarget, {
      controls: true,
      playbackRates: [0.5, 1, 2],
      autoplay: JSON.parse(this.data.get("autoplay")),
      fill: true,
      liveui: true,
      livetracker: true,
    });

    this.videoPlayer.addClass('video-js')
    this.videoPlayer.addClass('vjs-big-play-centered');
  }
}