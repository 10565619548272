import { Controller } from "stimulus";

import { throttle } from "../../utils/throttle";

export default class extends Controller {
  static targets = ["bar", "content"];

  connect() {
    this._initializeIntersectionObserver();
    document.addEventListener(
      "scroll",
      throttle(this._handleScroll.bind(this), 50)
    );
    this._handleScroll(); // Initial call to set progress on page load
  }

  disconnect() {
    document.removeEventListener("scroll", this._handleScroll.bind(this));
    this.observer.disconnect();
  }

  _initializeIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: "0px 0px -100% 0px",
      threshold: 0,
    };
    this.observer = new IntersectionObserver(
      this._observerCallback.bind(this),
      options
    );

    const firstParagraph = this.contentTarget.querySelector("p");
    this.observer.observe(firstParagraph);
  }

  _observerCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this._showProgressBar();
      }
    });
  }

  _handleScroll(evt) {
    const contentRect = this.contentTarget.getBoundingClientRect();
    const contentHeight = contentRect.height;
    const scrolled = contentRect.top;
    const progressDiv = this.barTarget.querySelector("div");
    const viewportHeight = window.innerHeight;

    const progress = Math.abs(scrolled) / (contentHeight - viewportHeight);

    if (scrolled > 0) {
      progressDiv.style.width = "0%";
    }

    if (scrolled < 0) {
      if (this.barTarget.classList.contains("hidden")) {
        this._showProgressBar();
      }
      const progress = Math.abs(scrolled) / (contentHeight - viewportHeight);
      progressDiv.style.width = `${progress * 100}%`;
    }
  }

  _showProgressBar() {
    this.barTarget.classList.remove("hidden");
  }
}
