import { Controller } from "stimulus";
import "flatpickr";

export default class extends Controller {
  static values = {
    dateFormat: String,
    altFormat: String,
    minDate: String,
    maxDate: String,
    enableTime: Boolean,
  };

  connect() {
    this._initializeFlatpickr();
  }

  disconnect() {
    this.flatpickr.destroy();
  }

  _initializeFlatpickr() {
    this.flatpickr = flatpickr(this.element, {
      dateFormat: this.dateFormatValue || "Y-m-d H:i",
      minDate: this.minDateValue || null,
      maxDate: this.maxDateValue,
      enableTime: this.enableTimeValue,
    });
  }
}
