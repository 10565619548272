document.addEventListener("turbolinks:load", function () {
  const triggers = document.querySelectorAll(
    '[data-behavior="collapsible-trigger"]'
  );

  triggers.forEach((trigger) => {
    trigger.addEventListener("click", function () {
      const content = this.nextElementSibling;
      const expanded = this.getAttribute("aria-expanded") === "true";

      if (!expanded) {
        // Open the content area
        content.style.height = content.scrollHeight + "px";
      } else {
        // Close the content area
        content.style.height = "0";
      }

      // Toggle aria-expanded attribute
      this.setAttribute("aria-expanded", !expanded);
    });
  });
});
