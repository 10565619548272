import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["menu", "overlay"];
  static values = { useClickOutside: Boolean };

  connect() {
    if (true) {
      useClickOutside(this, { element: this.menuTarget });
    }
  }

  open(event) {
    event.preventDefault();
    this._show();
  }

  close(event) {
    event.preventDefault();
    this._hide();
  }

  clickOutside(event) {
    if (this.menuTarget.classList.contains("slide-in")) {
      // We use this because our sign out button (a link that posts a form)
      // wasn't working properly. It was closing the drawer and 'preventing'
      // the form from being submitted.
      if (event.target.type && event.target.type != "submit") {
        event.preventDefault();
      }

      this._hide();
    }
  }

  _show() {
    this.menuTarget.classList.remove("slide-out");
    this.menuTarget.classList.add("slide-in");
    this.overlayTarget.classList.remove("hidden");
    this.overlayTarget.classList.remove("fade-out");
    this.overlayTarget.classList.add("fade-in");
  }

  _hide() {
    this.menuTarget.classList.remove("slide-in");
    this.overlayTarget.classList.add("fade-out");
    this.menuTarget.classList.add("slide-out");
    this.overlayTarget.classList.remove("fade-in");
    this.overlayTarget.classList.add("hidden");
  }
}
