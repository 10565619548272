import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "inlineField",
    "field",
    "fieldContainer",
    "errors",
    "template",
    "coverURI",
    "width",
    "height",
    "duration",
    "sdEmbedURI",
    "hdEmbedURI",
    "videoCard",
    "videoThumbnail",
    "videoTitle",
    "videoDescription",
  ];
  static values = { url: String };

  fetchVideo(e) {
    let provider = this.inlineFieldTarget.innerHTML.trim().split(".")[0];
    let video_id = e.target.value;

    if (provider == "vimeo") {
      let self = this;
      console.log(this.urlValue);
      Rails.ajax({
        url: this.urlValue,
        type: "get",
        data: `video_id=${video_id}`,
        success: function (response) {
          self._handleSuccessfulFetch(response);
        },
        error: function (response) {
          self._handleErrors(response);
        },
      });
    }
  }

  _handleSuccessfulFetch(response) {
    if (response.error) {
      this.errorsTarget.innerHTML = response.error;
    } else {
      if (this.errorsTarget.innerHTML.length > 0) {
        this.errorsTarget.innerHTML = "";
      }

      if (this.coverURITarget) {
        this._setFieldAttribute(this.coverURITarget, response.cover_uri);
      }

      if (this.widthTarget) {
        this._setFieldAttribute(this.widthTarget, response.width);
      }

      if (this.heightTarget) {
        this._setFieldAttribute(this.heightTarget, response.height);
      }

      if (this.durationTarget) {
        this._setFieldAttribute(this.durationTarget, response.duration);
      }

      if (this.sdEmbedURITarget) {
        this._setFieldAttribute(this.sdEmbedURITarget, response.sd_embed_uri);
      }

      if (this.hdEmbedURITarget) {
        this._setFieldAttribute(this.hdEmbedURITarget, response.hd_embed_uri);
      }

      this._showPreview(response);
    }
  }

  _handleErrors(response) {}

  _showPreview(response) {
    let content = this.templateTarget.innerHTML;
    let videoCard = document.querySelector(
      "[data-target='video-data-fetcher.videoCard']"
    );

    if (!videoCard) {
      this.fieldContainerTarget.insertAdjacentHTML("afterend", content);
      this.videoThumbnailTarget.src = response.cover_uri;
      this.videoTitleTarget.innerHTML = response.name;
      this.videoDescriptionTarget.innerHTML = `<span class="text-sm text-gray-900 font-semibold">Duración: </span><span class="text-sm text-gray-600">${Math.floor(
        response.duration / 60
      )} minutos</span>`;
    } else {
      videoCard.remove();
      this.fieldContainerTarget.insertAdjacentHTML("afterend", content);

      this.videoThumbnailTarget.src = response.cover_uri;
      this.videoTitleTarget.innerHTML = response.name;
      this.videoDescriptionTarget.innerHTML = `<span class="text-sm text-gray-900 font-semibold">Duración: </span><span class="text-sm text-gray-600">${Math.floor(
        response.duration / 60
      )} minutos</span>`;
    }
  }

  _setFieldAttribute(el, value) {
    if (el) {
      el.value = value;
    } else {
      return null;
    }
  }
}
