import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "audio",
    "playButton",
    "pauseButton",
    "progressBar",
    "currentTime",
    "totalTime",
    "volumeOnIcon",
    "volumeOffIcon",
  ];

  connect() {
    this.audioTarget.addEventListener(
      "timeupdate",
      this._updateProgress.bind(this)
    );
    this.audioTarget.addEventListener(
      "loadedmetadata",
      this._updateTotalTime.bind(this)
    );
    this.audioTarget.addEventListener("ended", this._reset.bind(this));

    this._resetProgressBar();
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown.bind(this));
  }

  toggle(evt) {
    evt.preventDefault();
    const audio = this.audioTarget;
    if (audio.paused) {
      this._play();
    } else {
      this._pause();
    }
  }

  navigate(evt) {
    evt.preventDefault();

    if (evt.target.closest(".audio-player__button")) {
      return;
    }

    if (evt.target.closest(".audio-player__volume-control")) {
      return;
    }

    const progressBarContainer = this.progressBarTarget.parentElement;
    const rect = progressBarContainer.getBoundingClientRect();
    const clickX = evt.clientX - rect.left;
    const totalWidth = progressBarContainer.offsetWidth;
    const clickPercent = clickX / totalWidth;
    const newTime = clickPercent * this.audioTarget.duration;
    this.audioTarget.currentTime = newTime;
  }

  toggleMute() {
    const audio = this.audioTarget;
    if (audio.muted) {
      audio.muted = false;
      this.volumeOnIconTarget.classList.remove("hidden");
      this.volumeOffIconTarget.classList.add("hidden");
    } else {
      audio.muted = true;
      this.volumeOnIconTarget.classList.add("hidden");
      this.volumeOffIconTarget.classList.remove("hidden");
    }
  }

  _play() {
    this.audioTarget.play();
    this._showPauseButton();
  }

  _pause() {
    this.audioTarget.pause();
    this._showPlayButton();
  }

  _updateProgress() {
    const audio = this.audioTarget;
    const progress = (audio.currentTime / audio.duration) * 100;
    this.progressBarTarget.style.width = `${progress}%`;

    this._updateCurrentTime();
  }

  _updateCurrentTime() {
    const currentTime = this._formatTime(this.audioTarget.currentTime);
    this.currentTimeTarget.textContent = currentTime;
  }

  _updateTotalTime() {
    const totalTime = this._formatTime(this.audioTarget.duration);
    this.totalTimeTarget.textContent = totalTime;
  }

  _resetProgressBar() {
    this.progressBarTarget.style.width = "0%";
    this.currentTimeTarget.textContent = "0:00";
    this.totalTimeTarget.textContent = "0:00";
  }

  _reset() {
    this.audioTarget.pause();
    this.audioTarget.currentTime = 0;
    this._resetProgressBar();
    this._showPlayButton();
  }

  _showPlayButton() {
    this.playButtonTarget.classList.remove("hidden");
    this.pauseButtonTarget.classList.add("hidden");
  }

  _showPauseButton() {
    this.playButtonTarget.classList.add("hidden");
    this.pauseButtonTarget.classList.remove("hidden");
  }

  _formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  handleKeydown(event) {
    if (
      event.target.tagName === "INPUT" ||
      event.target.tagName === "TEXTAREA" ||
      event.target.isContentEditable
    ) {
      // Do not handle keydown events if the target is an input field, textarea, or content editable element
      return;
    }

    if (event.key === "ArrowLeft") {
      this._rewind();
    } else if (event.key === "ArrowRight") {
      this._forward();
    } else if (event.key === " " && !event.ctrlKey && !event.metaKey) {
      console.log("Pausing");
      // Prevent default only if space is pressed without Ctrl or Meta (Cmd on Mac)
      event.preventDefault();
      this.toggle(event);
    }
  }

  _rewind() {
    this.audioTarget.currentTime = Math.max(
      0,
      this.audioTarget.currentTime - 5
    );
  }

  _forward() {
    this.audioTarget.currentTime = Math.min(
      this.audioTarget.duration,
      this.audioTarget.currentTime + 5
    );
  }
}
