import { Controller } from "stimulus";
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = ["questions", "questionField", "form", "submitButton"];

  connect() {
    this.consumer = consumer.subscriptions.create(
      { channel: "LiveStreamQuestionChannel", id: this.data.get("id") },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );

    this.formTarget.addEventListener("keydown", (e) => {
      if (e.keyCode == 13) {
        e.preventDefault();
        if (this.questionFieldTarget.value) {
          this.formTarget.submit();
          this.formTarget.reset();
          this._scrollToBottom();
        }
      }
    });
  }

  handleChange(ev) {
    ev.preventDefault();
    let question = ev.target.value;
    if (question.length > 0) {
      this._enableSubmitButton();
    }
  }

  clearMessageForm() {
    this.messageFieldTarget.value = "";
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.message && data.message.status == 204) {
      let question = document.querySelector(
        `#live_stream_question_${data.message.live_stream_question_id}`
      );
      question.remove();
    }

    if (data.message && data.message.status != 204) {
      this.questionsTarget.insertAdjacentHTML("beforeend", data.message);
      this.formTarget.reset();
      this._disableSubmitButton();
      this._scrollToBottom();
    }
  }

  _enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove(
      "cursor-not-allowed",
      "bg-indigo-300"
    );
    this.submitButtonTarget.classList.add(
      "bg-indigo-700",
      "hover:bg-indigo-800"
    );
  }

  _disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.remove(
      "bg-indigo-700",
      "hover:bg-indigo-800"
    );
    this.submitButtonTarget.classList.add(
      "cursor-not-allowed",
      "bg-indigo-300"
    );
  }

  _scrollToBottom() {
    this.questionsTarget.scrollBy({
      top: this.questionsTarget.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }
}
