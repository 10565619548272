import { Controller } from "stimulus";

export default class extends Controller{
  static targets = ["radio", "field", "border"];

  initialize(){
    let planName = new URLSearchParams(window.location.search).get("plan") || this.data.get("defaultValue");
    this._makeSelectedPlanInputChecked(planName);
    this._makeSelectedPlanBorderHighlighted(planName)
  }

  select(event){
    let selectedField = this.fieldTargets.filter((t) => {
      return t.value == event.currentTarget.dataset.radioButtonPlan;
    })[0];

    let unselectedField = this.fieldTargets.filter((t) => {
      return t.value != event.currentTarget.dataset.radioButtonPlan;
    })[0]

    let selectedBorder = document.querySelector(`[data-radio-button-border-plan=${event.currentTarget.dataset.radioButtonPlan}]`)
    let unselectedBorder = this.borderTargets.filter((t) => {
      return t != selectedBorder;
    })[0]

    selectedBorder.classList.add("border-gray-600");
    unselectedBorder.classList.remove("border-gray-600");

    selectedField.checked = true;
    unselectedField.checked = false;
  }

  _makeSelectedPlanInputChecked(planName){
    let checkedRadioTarget = this.fieldTargets.filter((t) => {
      return t.value == planName;
    })[0];

    checkedRadioTarget.setAttribute("checked", true);
  }

  _makeSelectedPlanBorderHighlighted(planName){
    let highlightedBorder = this.borderTargets.filter((t) => {
      return t.dataset.radioButtonBorderPlan == planName
    })[0];

    highlightedBorder.classList.remove("border-transparent");
    highlightedBorder.classList.add("border-gray-600");
  }
}