// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start();
require("turbolinks").start();
require("channels");

import "../controllers/frontend";
import "../controllers/accordion_controller";
import "../controllers/countdown_timer_controller";
import "../controllers/date_field_controller";
import "../controllers/dismiss_controller";
import "../controllers/dropdown_controller";
import "../controllers/modal_controller";
import "../controllers/nested_form_controller";
import "../controllers/notifications_controller";
import "../controllers/radio_button_select_controller";
import "../controllers/tabs_controller";
import "../controllers/toggle_checkbox_controller";
import "../controllers/tooltip_controller";
import "../controllers/validation_controller";

import "./animated_counter";
import "./collapsible";
import "./smooth_scroll";
import "./splide";
import setLocalTime from "./local_time";
import "stylesheets/application";

if (!Turbolinks) {
  location.reload();
}

Turbolinks.dispatch("turbolinks:load");

setLocalTime();
