import { Controller } from "stimulus";
import validate from "validate.js";

export default class extends Controller {
  static targets = ["form", "submit", "input"];

  validateField(event) {
    var errors = validate(this.formTarget, this.constraints());

    this.showErrors(errors || {});

    if (errors == null) {
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove("bg-gray-400", "cursor-not-allowed");
      this.submitTarget.classList.add("bg-indigo-700", "cursor-pointer");
    }
  }

  showErrors(errors) {
    for (let input of this.inputTargets) {
      this.showErrorsForInput(input, errors[input.getAttribute("name")]);
    }

    this.submitTarget.disabled = true;
    this.submitTarget.classList.remove("bg-indigo-700", "cursor-pointer");
    this.submitTarget.classList.add("bg-gray-400", "cursor-not-allowed");
  }

  constraints() {
    var constraints = {};
    for (let input of this.inputTargets) {
      constraints[input.name] = JSON.parse(input.getAttribute("data-validate"));
    }
    return constraints;
  }

  showErrorsForInput(input, errors) {
    console.log(input, errors);
    this.clearErrors(input);

    if (errors) {
      input.classList.add(...["border", "border-red-400"]);
      this.insertErrorMessages(input, errors);
    } else {
      input.classList.remove(...["border-red-400"]);
    }
  }

  clearErrors(input) {
    console.log(input.name);
    if (document.getElementById(`error_${input.name}`)) {
      document.getElementById(`error_${input.name}`).remove();
    }
  }

  insertErrorMessages(input, errors) {
    var html = document.createElement("div");
    html.innerHTML = errors.join(" ");
    html.id = `error_${input.getAttribute("name")}`;
    html.classList.add(...["text-red-500", "text-sm"]);
    input.after(html);
  }
}
