import {Controller} from "stimulus";

export default class extends Controller{
  static targets = ["voteCount"];

  upvote(e){
    e.preventDefault();
    let self = this;
    
    Rails.ajax({
      url: this.data.get("upvoteUrl"),
      type: "POST",
      success: function(suggestionVotes){
        self._updateVoteCount(suggestionVotes)
      },
      error: function(error){
      }
    })
  }

  downvote(e){
    e.preventDefault();
    let self = this;

    Rails.ajax({
      url: this.data.get("downvoteUrl"),
      type: "POST",
      success: function(suggestionVotes){
        self._updateVoteCount(suggestionVotes)
      },
      error: function(error){
      }
    })
  }

  _updateVoteCount(votes){
    this.voteCountTarget.innerHTML = votes
  }
}